<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="news & stories" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container"> <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p> </div>
            </section>
            <section class="main-content">
                <div class="container">
                    <p v-html="this.pageData.attributes.field_description_w_summary.value">
                    </p>
                    <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                </div>
            </section>
        </main>

    </div>
</template>
<script>
import Attachments from '../components/Attachments.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchSinglePage } from '../libs/drupalClient'
    export default {
        name: 'news',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "9079888c-4718-411e-8583-06b24a4150d8",
                newsData: null
            }
        },
        computed: {},
        methods: {
            logourl(index) {
                return process.env.VUE_APP_ENDPOINT + this.pageData.field_page_images[index].attributes.uri.url
            },
            personphoto(node) {
                var f = this.newsData.filter(p => p.attributes.drupal_internal__nid == node.attributes.drupal_internal__nid)
                if(f[0].field_image)
                    return process.env.VUE_APP_ENDPOINT + f[0].field_image.attributes.uri.url
                else 
                    return ''
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text','field_attachments']
            })
            .then(res => {
                this.pageData = res
                //console.log('pagedata', res)
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .main-content ::v-deep button {
        background-color: $darkblue;
        color: #fff;
        margin: 0px auto;
        display: flex;
        color: #fff;
        a {
            color: #fff;
        }
    }
</style>